<template>
  <div class="login-box">
    <div class="login-logo">
      <!-- <router-link to="/dashboard"> <b>Luke v2</b></router-link> -->
       <img src="./../assets/img/Logo-LUKE.png" alt="AdminLTE Logo" style="width: 50%;" class="text-center"/>
    </div>
    <!-- /.login-logo -->
    <div class="card bg-primary">
      <div class="card-body login-card-body">
        
        <p class="login-box-msg">Bitte melde Dich mit Deinen Zugangsdaten an</p>

        <form v-on:submit.prevent="login">
          <div class="input-group mb-3">
            <input
              type="text"
              ref="username"
              class="form-control"
              placeholder="Username"
              v-model="username"
            />
            <div class="input-group-append">
              <span class="fas fa-envelope input-group-text"></span>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              type="password"
              ref="password"
              class="form-control"
              placeholder="Passwort"
              v-model="password"
            />
            <div class="input-group-append">
              <span class="fas fa-lock input-group-text"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
                <span v-if="has_error == true" class="text-danger"><strong>Benutzer / Passwort falsch</strong></span>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button
                type="submit"
                class="btn btn-primary btn-block btn-flat"
                @click="login"
              >
                Anmelden
              </button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</template>

<script>
//import auth from "./../controllers/auth.controller";
export default {
  name: "Login",
  data: function() {
    return {
      username: null,
      password: null,
      has_error: false,
      errors: ""
    };
  },
  methods: {
    login: function() {
      // var app = this;
      this.has_error = false;
      this.$auth
      .login({
        data: {
          username: this.username,
          password: this.password
        },
        // success: function() {
        //   // handle redirection
        //   app.success = true;
        //   const redirectTo = "dashboard";
        //   this.$router.push({ name: redirectTo });
        // },
        // error: function(res) {
        //   app.has_error = true;
        //   app.error = res.response.data.error;
        // },
        // rememberMe: true,
        // fetchUser: true
      })
      .catch((res) => {
        this.has_error = true;
        this.errors = res.response.data.error;    
      })
    }
  },
  created: function() {
    document.body.classList.add("login-page");
  },
  destroyed: function() {
    document.body.classList.remove("login-page");
  }
};
</script>
